var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ((_vm.$route.meta.title) + " " + (_vm.data.length > 0 ? ("(" + (_vm.data.length) + ")") : ''))
          )}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.login.role_id == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"teal","loading":_vm.btn_xls_ldg,"disabled":_vm.data.length == 0},on:{"click":function($event){$event.preventDefault();return _vm.downloadXls.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v(" mdi-table-arrow-down ")])],1)]}}],null,false,2107618042)},[_c('span',{domProps:{"textContent":_vm._s('Descargar tabla XLS')}})]):_vm._e(),_c('BtnCircle',{attrs:{"tLabel":"Especialidades","tPosition":"bottom","icon":"mdi-clipboard-list","color":"info","size":"x-small","to":{ name: 'especialidades.tipos' }}}),(_vm.$store.getters.getLogin.permissions.doctor_groups.read)?_c('BtnCircle',{attrs:{"tLabel":"Redes","tPosition":"bottom","icon":"mdi-plus-network","color":"warning","size":"x-small","to":{ name: 'redes.medicos' }}}):_vm._e(),(
            _vm.login.permissions.doctor_prerevision &&
            _vm.login.permissions.doctor_prerevision.read
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-badge',{staticClass:"m-btn-badge",attrs:{"color":_vm.doctors_prerevision_total > 0 ? 'amber darken-3' : 'grey',"content":_vm.doctors_prerevision_total > 0 ? _vm.doctors_prerevision_total : '',"overlap":""}},[_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"color":_vm.doctors_prerevision_total > 0 ? 'amber darken-3' : 'grey',"x-small":"","dark":"","fab":"","loading":_vm.doctors_prerevision_loading,"to":{ name: 'medicos.prerevisiones' }}},on),[_c('v-icon',[_vm._v(" mdi-file-sign ")])],1)],1)]}}],null,false,3760025125)},[_c('span',{domProps:{"textContent":_vm._s('Pre revisiones')}})]):_vm._e(),(_vm.$store.getters.getLogin.permissions.doctors.create)?_c('BtnCircle',{attrs:{"tLabel":"Crear","tPosition":"bottom","icon":"mdi-plus","color":"success","size":"x-small","to":{ name: (_vm.page_route + ".store") }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Selecciona una opción...","items":_vm.filters,"item-value":"value","item-text":function (v) { return v.name; },"dense":""},on:{"change":_vm.getData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[(_vm.data.length > 0)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('b',[_vm._v(_vm._s(index + 1))])]}},{key:"item.valid",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":item.valid != null ? (item.valid ? 'success' : 'error') : ''}},[_vm._v(" mdi-"+_vm._s(item.valid != null ? item.valid ? "check-" : "alert-" : "")+"decagram ")])],1),(!item.url_validation)?_c('div',[_c('small',[_c('i',[_vm._v("Archivo valid. pendiente")])])]):_vm._e()]}},{key:"item.observation",fn:function(ref){
          var item = ref.item;
return [(item.observation)?_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}}):_vm._e()]}},{key:"item.revision",fn:function(ref){
          var item = ref.item;
return [(item.revision)?_c('v-icon',{attrs:{"small":"","color":"success"},domProps:{"textContent":_vm._s('mdi-check')}}):_c('v-icon',{attrs:{"small":"","color":"orange"},domProps:{"textContent":_vm._s('mdi-alert')}})]}},{key:"item.user_id",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.user_id ? 'success' : ''},domProps:{"textContent":_vm._s(item.user_id ? 'mdi-check' : 'mdi-close')}})]}},{key:"item.doctor_revision_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","color":item.doctor_revision && item.doctor_revision.accepted
                    ? 'success'
                    : item.doctor_revision == null
                    ? ''
                    : 'warning'}},[_vm._v(" "+_vm._s(item.doctor_revision && item.doctor_revision.accepted ? "mdi-check" : "mdi-alert")+" ")])],1),_c('div',{staticClass:"text-center"},[(item.doctor_revision_id)?_c('small',[_c('div',[_vm._v(" "+_vm._s("R" + (item.doctor_revision_revision ? 2 : 1) + "(" + item.doctor_revision_id + ")")+" ")]),(item.doctor_revision)?_c('div',[(item.doctor_revision.bank_id)?_c('div',[_c('b',[_vm._v("Banco: ")]),_vm._v(" "+_vm._s(item.doctor_revision.bank.bank)+" ")]):_vm._e(),(item.doctor_revision.account_number)?_c('div',[_c('b',[_vm._v("N. Cuenta: ")]),_vm._v(" "+_vm._s(item.doctor_revision.account_number)+" ")]):_vm._e(),(item.doctor_revision.clabe)?_c('div',[_c('b',[_vm._v("CLABE: ")]),_vm._v(" "+_vm._s(item.doctor_revision.clabe)+" ")]):_vm._e(),(item.doctor_revision.bank_account_pdf)?_c('div',[_c('b',[_vm._v("Cuenta bancaria: ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","href":_vm.url_documents +
                            '/doctors/' +
                            item.doctor_revision.bank_account_pdf,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])],1):_vm._e(),(item.doctor_revision.account_validation_pdf)?_c('div',[_c('b',[_vm._v("Valid. cuenta: ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","href":_vm.url_documents +
                            '/doctors/' +
                            item.doctor_revision.account_validation_pdf,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])],1):_vm._e()]):_vm._e()]):_vm._e()])]}},{key:"item.doctor_revision.revision_user.email",fn:function(ref){
                            var item = ref.item;
return [(
                item.doctor_revision && item.doctor_revision.revision_user
              )?_c('div',[_vm._v(" "+_vm._s(item.doctor_revision.revision_user.email)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.doctor_revision.revision_date)+" ")])]):_vm._e()]}},{key:"item.doctor_revision.confirmation_user.email",fn:function(ref){
              var item = ref.item;
return [(
                item.doctor_revision && item.doctor_revision.confirmation_user
              )?_c('div',[_vm._v(" "+_vm._s(item.doctor_revision.confirmation_user.email)+" ")]):_vm._e()]}},{key:"item.doctor_prerevision",fn:function(ref){
              var item = ref.item;
return [(item.doctor_prerevision)?_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-alert ")]):_c('span',{domProps:{"textContent":_vm._s('-')}})]}},{key:"item.created_by_service",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.created_by_service ? 'success' : ''},domProps:{"textContent":_vm._s(item.created_by_service ? 'mdi-check' : 'mdi-close')}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"primary","to":{
                    name: (_vm.page_route + ".view"),
                    params: { id: item.id },
                  }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-eye-outline')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }