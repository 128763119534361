<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                data.length > 0 ? `(${data.length})` : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xls_ldg"
                :disabled="data.length == 0"
                @click.prevent="downloadXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Especialidades"
            tPosition="bottom"
            icon="mdi-clipboard-list"
            color="info"
            size="x-small"
            :to="{ name: 'especialidades.tipos' }"
          />
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.doctor_groups.read"
            tLabel="Redes"
            tPosition="bottom"
            icon="mdi-plus-network"
            color="warning"
            size="x-small"
            :to="{ name: 'redes.medicos' }"
          />
          <v-tooltip
            v-if="
              login.permissions.doctor_prerevision &&
              login.permissions.doctor_prerevision.read
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-badge
                class="m-btn-badge"
                :color="
                  doctors_prerevision_total > 0 ? 'amber darken-3' : 'grey'
                "
                :content="
                  doctors_prerevision_total > 0 ? doctors_prerevision_total : ''
                "
                overlap
              >
                <v-btn
                  :color="
                    doctors_prerevision_total > 0 ? 'amber darken-3' : 'grey'
                  "
                  class="ml-1"
                  v-on="on"
                  x-small
                  dark
                  fab
                  :loading="doctors_prerevision_loading"
                  :to="{ name: 'medicos.prerevisiones' }"
                >
                  <v-icon> mdi-file-sign </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span v-text="'Pre revisiones'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.doctors.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select
            label="Selecciona una opción..."
            v-model="filter"
            :items="filters"
            item-value="value"
            :item-text="(v) => v.name"
            dense
            @change="getData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-text-field
            v-if="data.length > 0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.index="{ item, index }">
              <b>{{ index + 1 }}</b>
            </template>
            <template v-slot:item.valid="{ item }">
              <div>
                <v-icon
                  :color="
                    item.valid != null ? (item.valid ? 'success' : 'error') : ''
                  "
                >
                  mdi-{{
                    item.valid != null
                      ? item.valid
                        ? "check-"
                        : "alert-"
                      : ""
                  }}decagram
                </v-icon>
              </div>
              <div v-if="!item.url_validation">
                <small><i>Archivo valid. pendiente</i></small>
              </div>
            </template>
            <template v-slot:item.observation="{ item }">
              <v-icon
                v-if="item.observation"
                small
                color="orange"
                v-text="'mdi-alert'"
              />
            </template>
            <template v-slot:item.revision="{ item }">
              <v-icon
                v-if="item.revision"
                small
                color="success"
                v-text="'mdi-check'"
              />
              <v-icon v-else small color="orange" v-text="'mdi-alert'" />
            </template>
            <template v-slot:item.user_id="{ item }">
              <v-icon
                small
                v-text="item.user_id ? 'mdi-check' : 'mdi-close'"
                :color="item.user_id ? 'success' : ''"
              />
            </template>
            <template v-slot:item.doctor_revision_id="{ item }">
              <div class="text-center">
                <v-icon
                  small
                  :color="
                    item.doctor_revision && item.doctor_revision.accepted
                      ? 'success'
                      : item.doctor_revision == null
                      ? ''
                      : 'warning'
                  "
                >
                  {{
                    item.doctor_revision && item.doctor_revision.accepted
                      ? "mdi-check"
                      : "mdi-alert"
                  }}
                </v-icon>
              </div>
              <div class="text-center">
                <small v-if="item.doctor_revision_id">
                  <div>
                    {{
                      "R" +
                      (item.doctor_revision_revision ? 2 : 1) +
                      "(" +
                      item.doctor_revision_id +
                      ")"
                    }}
                  </div>
                  <div v-if="item.doctor_revision">
                    <div v-if="item.doctor_revision.bank_id">
                      <b>Banco: </b>
                      {{ item.doctor_revision.bank.bank }}
                    </div>
                    <div v-if="item.doctor_revision.account_number">
                      <b>N. Cuenta: </b>
                      {{ item.doctor_revision.account_number }}
                    </div>
                    <div v-if="item.doctor_revision.clabe">
                      <b>CLABE: </b>
                      {{ item.doctor_revision.clabe }}
                    </div>
                    <div v-if="item.doctor_revision.bank_account_pdf">
                      <b>Cuenta bancaria: </b>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/doctors/' +
                              item.doctor_revision.bank_account_pdf
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </div>
                    <div v-if="item.doctor_revision.account_validation_pdf">
                      <b>Valid. cuenta: </b>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/doctors/' +
                              item.doctor_revision.account_validation_pdf
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </div>
                  </div>
                </small>
              </div>
            </template>
            <template
              v-slot:item.doctor_revision.revision_user.email="{ item }"
            >
              <div
                v-if="
                  item.doctor_revision && item.doctor_revision.revision_user
                "
              >
                {{ item.doctor_revision.revision_user.email }}
                <br />
                <small>
                  {{ item.doctor_revision.revision_date }}
                </small>
              </div>
            </template>
            <template
              v-slot:item.doctor_revision.confirmation_user.email="{ item }"
            >
              <div
                v-if="
                  item.doctor_revision && item.doctor_revision.confirmation_user
                "
              >
                {{ item.doctor_revision.confirmation_user.email }}
              </div>
            </template>
            <template v-slot:item.doctor_prerevision="{ item }">
              <v-icon v-if="item.doctor_prerevision" small color="warning">
                mdi-alert
              </v-icon>
              <span v-else v-text="'-'" />
            </template>
            <template v-slot:item.created_by_service="{ item }">
              <v-icon
                small
                v-text="item.created_by_service ? 'mdi-check' : 'mdi-close'"
                :color="item.created_by_service ? 'success' : ''"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  base64ToArrayBuffer,
  dateTimeToFile,
} from "../../control";
import { index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      page_route: "doctors",
      login: this.$store.getters.getLogin,
      loading: false,
      data: [],
      search: "",
      headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "IDM", filterable: true, value: "id" },
        { text: "Válido", filterable: true, value: "valid" },
        { text: "Nombre", filterable: true, value: "full_name" },
        { text: "Nivel", filterable: true, value: "doctor_level.name" },
        { text: "Especialidad", filterable: true, value: "specialty_type" },
        { text: "Cédula", filterable: true, value: "specialty_licence" },
        {
          text: "Consult. | Hosp.",
          filterable: true,
          value: "provider.trade_name",
        },
        { text: "Estado", filterable: true, value: "entity" },
        { text: "Dirección", filterable: true, value: "address" },
        { text: "Teléfono", filterable: true, value: "phone" },
        { text: "Tel. móvil", filterable: true, value: "cell_phone" },
        { text: "C. electrónico", filterable: true, value: "email" },
        { text: "Municipio", filterable: true, value: "municipality" },
        { text: "Observación", filterable: false, value: "observation" },
        { text: "Segunda firma red", filterable: false, value: "revision" },
        { text: "Usr. sist.", filterable: false, value: "revision" },
        {
          text: "Últ. rev.",
          filterable: false,
          value: "last_doctor_revision_accepted.id",
        },
        { text: "Rev. info.", filterable: false, value: "doctor_revision_id" },
        {
          text: "R1",
          filterable: true,
          value: "doctor_revision.revision_user.email",
        },
        {
          text: "R2",
          filterable: true,
          value: "doctor_revision.confirmation_user.email",
        },
        {
          text: "R2 fecha",
          filterable: true,
          value: "doctor_revision.confirmation_date",
        },
        {
          text: "Pre revisión",
          filterable: true,
          value: "doctor_prerevision",
        },
        {
          text: "Creado desde servicio",
          filterable: false,
          value: "created_by_service",
        },
        { text: "Acción", value: "action", sortable: false },
      ],
      filter: null,
      filters: [
        {
          value: 1,
          name: "TODOS",
        },
        {
          value: 3,
          name: "CREADOS DESDE SERVICIOS",
        },
        {
          value: 8,
          name: "CREADOS DESDE HONORARIOS MÉDICOS",
        },
        {
          value: 9,
          name: "CREADOS DESDE INFO",
        },
        {
          value: 2,
          name: "PENDIENTES DE VALIDAR SEGUNDA FIRMA",
        },
        {
          value: 5,
          name: "PENDIENTES DE REVISIÓN CON COBRO GENERADO",
        },
        {
          value: 7,
          name: "PENDIENTES DE VALIDAR PAGOS FUTUROS",
        },
        {
          value: 6,
          name: "RECHAZOS DE VALIDACIÓN SEGUNDA FIRMA",
        },
        {
          value: 4,
          name: "REVISIONES TERMINADAS",
        },
      ],
      doctors_prerevision_total: 0,
      doctors_prerevision_loading: true,
      btn_xls_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      index(this.page_route, this.login.token, [
        {
          name: "filter",
          value: this.filter,
        },
      ]).then((response) => {
        this.data = response.data;
        this.loading = false;
      });
    },
    downloadXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/doctors/xls",
        {
          items: this.data,
        },
        headersToken(this.login.token)
      ).then((res) => {
        if (res.data.success) {
          this.fileDownload(res.data.data, "xlsx", "asegurados");
          this.btn_xls_ldg = false;
        } else {
          this.btn_xls_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/doctors/prerevision/total`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.doctors_prerevision_total = resp.data.total;
      this.doctors_prerevision_loading = false;
    });
  },
};
</script>
<style>
.m-btn-badge {
  margin-right: 7px !important;
}
</style>